<template>
  <div class="container">
    <audit-header
      :showStatus="true"
      :data="auditData"
      :taskSubName="
        auditData.userTaskDetailAuditResultList && auditData.userTaskDetailAuditResultList.length
          ? auditData.userTaskDetailAuditResultList[0].taskSubName
          : ''
      "
      :auditTime="
        auditData.userTaskDetailAuditResultList && auditData.userTaskDetailAuditResultList.length
          ? auditData.userTaskDetailAuditResultList[0].auditTime
          : ''
      "
      :auditReason="
        auditData.userTaskDetailAuditResultList &&
        auditData.userTaskDetailAuditResultList.length &&
        auditData.auditReason
          ? auditData.userTaskDetailAuditResultList[0].auditReason
          : ''
      "
    />
    <div class="qs-list">
      <div class="qs-item" v-for="(item, index) in auditData.userTaskDetailAuditResultList" :key="index">
        <div class="qs-title">{{ index + 1 }}、{{ item.itemTitle }}</div>
        <!-- 采集项类型 0：问答；1：单选；2：多选；3：图片上传(单张)；4：图片上传(多张) 5.问答带正则-->
        <div class="answer" v-if="[0, 1, 2, 5].includes(item.itemType)">
          <div class="label">答：</div>
          <div class="content" v-if="item.itemType === 0 || item.itemType === 5">{{ item.itemAnswer }}</div>
          <div class="content" v-if="item.itemType === 1">{{ item.optionTitle }}</div>
          <div class="content" v-if="item.itemType === 2">
            <div class="option" v-for="(oItem, oIndex) in item.optionList" :key="oIndex">{{ oItem }}</div>
          </div>
        </div>

        <div class="image-wrap" v-if="item.itemType === 3">
          <van-image
            v-for="(data, index) in item.imgList"
            :key="index"
            class="image"
            fit="contain"
            :src="data"
            @click="handlePreview(data)"
          />
          <div v-if="item.itemSampleImg" class="example" @click="handlePreview(item.itemSampleImg)">
            查看示例
            <van-icon name="arrow" size="4.5vw" style="margin-left: 5px" />
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <button class="btn" @click="$router.go(-1)">返 回</button>
    </div>
  </div>
</template>

<script>
import { Image, ImagePreview } from 'vant'
import AuditHeader from './components/AuditHeader.vue'
import { listUserTaskDetailAuditResult } from '../../api/auditResult'
export default {
  components: { AuditHeader, [Image.name]: Image },
  data() {
    return {
      auditData: {
        auditReason: '',
        userTaskDetailAuditResultList: []
      }
    }
  },

  mounted() {
    document.title = '任务审核明细'
    this.getData()
  },

  methods: {
    handlePreview(path) {
      ImagePreview([path])
    },

    getData() {
      listUserTaskDetailAuditResult(this.$route.query.reportId).then((res) => {
        this.auditData = res.data.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
@vv: 7.5vw;

.conatiner {
  min-height: 100vh;
  background: #f5f5f5;
}

.qs-list {
  padding-bottom: 180 / @vv;
  .qs-item {
    box-sizing: border-box;
    width: 100%;
    margin: 20 / @vv 0;
    background: #fff;
    padding: 20 / @vv 40 / @vv 40 / @vv;
    line-height: 60 / @vv;
    color: #666;
    font-size: 32 / @vv;
    border-radius: 8 / @vv;
    .qs-title {
      margin-bottom: 10 / @vv;
    }
    .answer {
      display: flex;
      align-items: flex-start;
      .label {
        width: 66 / @vv;
      }
      .content {
        flex: 1;
      }
    }

    .image-wrap {
      margin-top: 20 / @vv;
      text-align: center;
      .image {
        width: 540 / @vv;
        height: 300 / @vv;
        padding: 4 / @vv;
        border: 1px solid #ccc;
        background: #f5f5f5;
        border-radius: 8 / @vv;
      }
      .example {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        color: #5a80b0;
        font-size: 24 / @vv;
        margin-top: 20 / @vv;
        padding-right: 50 / @vv;
      }
    }
  }
}

.footer {
  height: 150 / @vv;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 20 / @vv 30 / @vv;
  background: #fff;
  box-sizing: border-box;
  border-top: 1px solid #f5f5f5;
  .btn {
    width: 690 / @vv;
    height: 88 / @vv;
    line-height: 88 / @vv;
    text-align: center;
    border-radius: 44 / @vv;
    border: 1 / @vv solid #ccc;
    background: #fff;
    font-size: 30 / @vv;
    color: #666;
  }
}
</style>
