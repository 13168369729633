import request from '../utils/request.js'

// 审核列表
export function listUserTaskAuditResultReport(data) {
  return request().post('/api/con/v1/listUserTaskAuditResultReport', data)
}

// 审核详情
export function listUserTaskDetailAuditResult(reportId) {
  return request().get(`/api/con/v1/listUserTaskDetailAuditResult/${reportId}`)
}
