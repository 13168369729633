<template>
  <div class="audit-header">
    <div class="title">{{ data.terminalName }}</div>
    <div class="info-item">
      {{ data.taskProjectName }}
      <span v-if="taskSubName">：{{ taskSubName }}</span>
    </div>
    <div class="info-item">提交时间：{{ data.reportDate }}</div>
    <div class="info-item" v-if="auditTime">审核时间：{{ auditTime }}</div>
    <div class="info-item" v-if="auditReason">审核原因：{{ auditReason }}</div>
    <div class="tag" v-if="showStatus">审核未通过</div>
  </div>
</template>

<script>
export default {
  name: 'AuditHeader',
  props: ['showStatus', 'data', 'auditTime', 'taskSubName','auditReason']
}
</script>

<style lang="less" scoped>
@vv: 7.5vw;
.audit-header {
  padding: 20 / @vv 30 / @vv 40 / @vv;
  line-height: 60 / @vv;
  background: #24adfe;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  .title {
    font-size: 32 / @vv;
    font-weight: 600;
    margin-bottom: 10 / @vv;
  }
  .info-item {
    font-size: 28 / @vv;
    font-weight: 500;
  }
  .tag {
    height: 60 / @vv;
    line-height: 60 / @vv;
    padding: 0 17 / @vv;
    border-radius: 30 / @vv 0 0 30 / @vv;
    background: #fffbe6;
    color: #fab222;
    font-size: 22 / @vv;
    position: absolute;
    top: 20 / @vv;
    right: 0;
  }
}
</style>
