var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('audit-header',{attrs:{"showStatus":true,"data":_vm.auditData,"taskSubName":_vm.auditData.userTaskDetailAuditResultList && _vm.auditData.userTaskDetailAuditResultList.length
        ? _vm.auditData.userTaskDetailAuditResultList[0].taskSubName
        : '',"auditTime":_vm.auditData.userTaskDetailAuditResultList && _vm.auditData.userTaskDetailAuditResultList.length
        ? _vm.auditData.userTaskDetailAuditResultList[0].auditTime
        : '',"auditReason":_vm.auditData.userTaskDetailAuditResultList &&
      _vm.auditData.userTaskDetailAuditResultList.length &&
      _vm.auditData.auditReason
        ? _vm.auditData.userTaskDetailAuditResultList[0].auditReason
        : ''}}),_c('div',{staticClass:"qs-list"},_vm._l((_vm.auditData.userTaskDetailAuditResultList),function(item,index){return _c('div',{key:index,staticClass:"qs-item"},[_c('div',{staticClass:"qs-title"},[_vm._v(_vm._s(index + 1)+"、"+_vm._s(item.itemTitle))]),([0, 1, 2, 5].includes(item.itemType))?_c('div',{staticClass:"answer"},[_c('div',{staticClass:"label"},[_vm._v("答：")]),(item.itemType === 0 || item.itemType === 5)?_c('div',{staticClass:"content"},[_vm._v(_vm._s(item.itemAnswer))]):_vm._e(),(item.itemType === 1)?_c('div',{staticClass:"content"},[_vm._v(_vm._s(item.optionTitle))]):_vm._e(),(item.itemType === 2)?_c('div',{staticClass:"content"},_vm._l((item.optionList),function(oItem,oIndex){return _c('div',{key:oIndex,staticClass:"option"},[_vm._v(_vm._s(oItem))])}),0):_vm._e()]):_vm._e(),(item.itemType === 3)?_c('div',{staticClass:"image-wrap"},[_vm._l((item.imgList),function(data,index){return _c('van-image',{key:index,staticClass:"image",attrs:{"fit":"contain","src":data},on:{"click":function($event){return _vm.handlePreview(data)}}})}),(item.itemSampleImg)?_c('div',{staticClass:"example",on:{"click":function($event){return _vm.handlePreview(item.itemSampleImg)}}},[_vm._v(" 查看示例 "),_c('van-icon',{staticStyle:{"margin-left":"5px"},attrs:{"name":"arrow","size":"4.5vw"}})],1):_vm._e()],2):_vm._e()])}),0),_c('div',{staticClass:"footer"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返 回")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }